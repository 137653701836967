@import '../../marketplace.css';

.root {
  display: inline-block;
}

.bookingInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dateSection {
}
