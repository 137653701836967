@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  text-align: center;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.getStartedBtnWrapper {
  text-align: center;
}

.getStartedBtn {
  color: var(--marketplaceColor);
  border: solid 1px var(--marketplaceColor);
  border-radius: 4px;
  background-color: transparent;
  font-weight: var(--fontWeightSemiBold);
  margin: 30px auto 0;
  font-size: 24px;
  line-height: 29px;
  display: inline-block;
  padding: 13px 30px;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    width: 248px;
    font-size: 40px;
    line-height: 48px;
    padding: 20px 17px;
  }
}

.createListingLink {
  margin-top: 36px;

  @media (--viewportMedium) {
    margin-top: 52px;
  }
}
