@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
  max-width: 735px;
  font-weight: var(--fontWeightSemiBold);

  @media (--viewportMedium) {
    margin-bottom: 23px;
    text-align: left;
  }
}

.subTitle{
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
    font-weight: var(--fontWeightSemiBold);
    font-size: 24px;
  }
}

.wrapWriters{
  margin-top: 20px;
 
  @media (--viewportMedium) {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill,minmax(300px, 1fr));
    margin-top: 40px;    
  }
}

.locationBox {
  margin-bottom: 25px;
 
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  height: 220px;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 5px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
  @media (--viewportLargeWithPaddings) {
    font-size: 24px  
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.showAll{
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColorDark);
  display: block;
  margin-top: 26px;
  margin-bottom: 15px;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  } 
}

.nameLocation{
  color: var(--marketplaceColorDark);
  padding-right: 7px;
}