@import '../../marketplace.css';

:root {
  --width_Dropdown: 195px;
  --height_Dropdown: 39px;
}
.centerTable {
  width: 100%;
  height: var(--height_Dropdown);
}

#select-box {
  min-width: 320px;
  max-width: 30%;
  width: 100%;
  margin: 0 auto;
  padding: 2em;
  text-align: center;
  background: #fff;
}
.fauxSelect {
  list-style-type: none;
  padding: 0.4em 0;
  background: var(--matterColorLight);
  text-align: left;
  border: 1px solid var(--matterColorGrayDark);
  border-radius: 4px;
  cursor: pointer;
  height: var(--height_Dropdown);
  display: inline-block;
  position: relative;
  margin: 0;
  width: var(--width_Dropdown);

  -webkit-user-select: none; /* Chrome/Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;  
}

.fauxSelectOpen {
  border-radius: 4px 4px 0 0;
}

.fauxSelect li {
  padding: 0;
  text-indent: 1em;
  position: relative;
}
.fauxSelect li .options {
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 11px 0 0 0;
  background: transparent;
  position: absolute;
  width: calc(100% + 2px);
  max-height: 0px;
  overflow: hidden;
  border: 3px solid transparent;
  top: 1.2em;
  left: -1px;
  transition: all ease 0.25s;
  z-index: 2;
}

.fauxSelect li .options li:hover {
  background: var(--matterColorRedLightTransparent);
}
.fauxSelect li .options li:last-of-type {
  border-bottom: 0;
}

.fauxSelect li .options.open {
  background: var(--matterColorLight);
  max-height: 1000%;
  border: 1px solid var(--matterColorGrayDark);
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.fauxSelect:before {
  content: "";
  background-image: url('../../assets/icons/chevron-down.png');
  width: 15px;
  height: 8px;
  position: absolute;
  top: 40%;
  right: 10px;
  transition: all ease 0.25s;
  cursor: pointer;
}

.fauxSelect.fauxSelectOpen:before {
  content: "";
  transform: rotate(180deg);
  transition: all ease 0.25s;
}

.placeholder {
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightSemiBold);
  pointer-events: none;
  font-size: 16px;
  color: transparent;
  outline-style: none;
  border: none;

  @media(--viewportMedium) {
    margin-top: -5px;
  } 
}

.placeholderRequired {
  color: var(--matterColorAnti);
  border: none;
  pointer-events: none;
  position: relative;
  left: 9px;
  top: -7px;
  font-size: 20px;
  position: relative;
  top: -5px;
  left: 10px;
  outline-style: none;
  margin-top: -2px;
}

.inputGroup {
  height: 39px;
}

.inputGroup input {
  -webkit-appearance: none;
  cursor: pointer;
  width: 100%;
  height: 39px;
  border: none;
  z-index: 2;
}

.inputGroup label {
  position: absolute;
  top: 6px;
  left: -5px;
  z-index: -1;
  user-select: none;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  padding: 0;

  @media(--viewportMedium) {
    font-size: 20px;
  }
}



.clearButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.selectedItem {
  position: relative;
  top: -35px;
  left: 10px;
  font-size: 16px;

  @media(--viewportMedium) {
    font-size: 20px;
  }
}