@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.nameEvent,
.guestEvent {
  margin: 0 24px 38px 24px;

  @media (--viewportMedium) {
    flex-shrink: 0;
    margin: 0 0 38px 0;
  }
}

.containerSection {
  margin: 0 24px;

  @media (--viewportMedium) {
    flex-shrink: 0;
    margin: 0;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.guestCatering {
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.guestCatering input {
  border: 1px solid var(--matterColorAnti);
  width: 104px;
  height: 30px;
}

.guestEvent label {
  margin-bottom: 5px;
}
.guestEvent input {
  border: 1px solid var(--matterColorAnti);
  height: 40px;
}

.wrapFieldItem {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 110px 3fr 1fr;
}

.pricePackage {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.labelPackage label {
  font-size: 18px;
}

.wrapCatering {
  display: flex;
  place-content: space-between;
}

.titleCatering,
.titleSupplies {
  color: var(--marketplaceColor);
  margin: 0;
  font-size: 20px;
}

.subTitleCatering,
.subTitleSupplies {
  font-size: 14px;
  margin: 0;
  font-style: italic;
}

.titleGuestCatering {
  font-size: 15px;
}

.itemSupply {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.itemSupply input {
  border: 1px solid var(--matterColorAnti);
  width: 50px;
  height: 24px;
  font-size: 15px;
}

.nameSupply {
  /* word-break: break-all; */
  hyphens: auto;
}

.priceSupply,
.nameSupply,
.cateringLabel {
  font-size: 15px;
  font-weight: var(--fontWeightMedium);
}

.price {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 15px;
  font-weight: var(--fontWeightMedium);
}

.perGuest {
  font-size: 12px;
  padding-left: 4px;
}

.catering {
  display: flex;
  min-width: 175px;
}

.leftCatering {
  min-width: 175px;
}

.noCatering {
  font-size: 14px;
  line-height: 24px;
  font-style: italic;
  text-align: center;
}

.titleDate {
  font-size: 17.5px;
  margin-top: -10px;
  padding: 0 24px;

  @media (--viewportMedium) {
      padding: 0;
  }
}

.submitBookingForm {
  @apply --marketplaceButtonStylesPrimary;
  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  background-color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.requiredCatering {
  color: var(--failColor);
  font-size: 16px;
  line-height: 24px;
  margin-top: -15px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
}
}

.validationSupply {
  width: 300px;
}

.hasFoodAllowed {
  opacity: 0.3;
  pointer-events: none;
}