@import '../../marketplace.css';

.staticPageWrapper {
  background-color: var(--pinkBrownLightColor);
}

.sectionWrapper {
  max-width: 1264px;
  margin: auto;
}

/*--------hero section--------*/
.heroSection {
  background: url("../../assets/about-us-background.jpg") no-repeat left;
  background-size: cover;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.heroContent {
  margin: 167px auto 0;
  width: 250px;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    margin: 167px 8.6% 0 30%;
    width: 518px;
  }

  @media (--viewportLarge) {
    margin: 167px 8.6% 0 55%;
  }
}

.aboutUsLogo {
  display: none;

  @media (--viewportMedium) {
    margin: auto;
    display: block;
    width: 433px;
    height: 189px;
  }
}

.logoText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    font-size: 32px;
    text-align: center;
    font-weight: var(--fontWeightRegular);
    margin-bottom: 6px;
  }
}

.heroText {
  opacity: 0.59;
  background-color: var(--matterColorLight);
  padding: 6px;
  margin-bottom: 0;
  font-size: 21px;
  line-height: 25px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    padding: 70px 30px 40px;
    font-size: 30px;
    line-height: 35px;
  }
}

/*------Function section---------*/
.functionSection {
  padding: 30px 30px 0;
  border: solid 1px var(--matterColorGrayDarkLight);
  background-color: var(--pinkWhiteBackgroundColor);

  @media (--viewportMedium) {
    padding: 36px;
    border: none;
    background-color: transparent;
  }
}

.functionTitle {
  text-align: center;
  color: var(--matterColorDark);
  font-weight: var(--fontWeightBold);
  font-size: 32px;
  line-height: 37px;
  margin-bottom: 25px;

  @media (--viewportMedium) {
    text-align: left;
    margin-bottom: 53px;
    font-size: 48px;
    line-height: 56px;
  }
}

.functionList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.functionItem {
  width: 100%;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    width: 30.5%;
    margin-bottom: 0;
  }
}

.functionItemImg {
  background-size: cover;
  width: 100%;
  background-position: center;
}

.functionItemLabel {
  color: var(--matterColorDark);
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
  font-size: 24px;
  line-height: 29px;

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 42px;
  }
}

.functionItemContent {
  font-weight: var(--fontWeightRegular);
  font-size: 21px;
  line-height: 25px;

  @media (--viewportMedium) {
    font-size: 26px;
    line-height: 30px;
  }
}
/*-------Mission section----------*/
.missionSection {
  padding: 34px 30px 63px;

  @media (--viewportMedium) {
    padding: 30px 38px;
    border: solid 1px var(--matterColorGrayDarkLight);
    background-color: var(--pinkWhiteBackgroundColor);
  }
}

.missionTitle {
  text-align: center;
  color: var(--matterColorDark);
  font-weight: var(--fontWeightBold);
  margin-top: 0;
  font-size: 32px;
  line-height: 37px;

  @media (--viewportMedium) {
    text-align: left;
    font-size: 48px;
    line-height: 56px;
  }
}

.mission1,
.mission2,
.mission3 {
  display: flex;
  flex-wrap: wrap;
}

.missionTitle1,
.missionTitle2,
.missionTitle3 {
  width: 100%;
  text-align: center;
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    width: 40%;
    font-size: 42px;
    line-height: 49px;
    padding: 38px 53px;
    margin: 4px 0;
  }
}

.missionContent1,
.missionContent2,
.missionContent3 {
  width: 100%;
  font-size: 21px;
  line-height: 25px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    width: 60%;
    font-size: 26px;
    line-height: 30px;
    padding: 25px 36px;
    border: solid 1px rgba(112, 112, 112, 0.61);
    margin: 4px 0;
  }
}

.missionTitle2 {
  @media (--viewportMedium) {
    width: 36%;
    order: 2;
  }
}

.missionTitle3 {
  @media (--viewportMedium) {
    width: 28%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.missionContent2 {
  @media (--viewportMedium) {
    width: 64%;
    order: 1;
  }
}

.missionContent3 {
  @media (--viewportMedium) {
    width: 72%;
  }
}

/*-------Contact section---------*/
.sectionContact {
  display: none;
  margin-top: 50px;
  margin-bottom: 26px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.contactDetail {
  width: 37.5%;
  border: solid 1px var(--matterColorGrayDarkLight);
  background-color: var(--pinkWhiteBackgroundColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.contactLabel {
  text-decoration: underline;
  font-weight: var(--fontWeightSemiBold);
  font-size: 28px;
  line-height: 33px;
}

.contactDetailBody {
  font-weight: var(--fontWeightRegular);
  font-size: 22px;
  line-height: 28px;

  @media (--viewportLarge) {
    font-size: 28px;
    line-height: 33px;
  }
}

.contactImg {
  width: 62.5%;

  & img {
    width: 100%;
  }
}
