@import '../../marketplace.css';

.root {
  width: 24px;
  height: 24px;
  stroke: var(--marketplaceColor);
}

.marketplaceFill {
  stroke: transparent;
  fill: var(--marketplaceColor);
}
