@import '../../marketplace.css';

.root {
}

.bookingInfo {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: var(--fontWeightSemiBold);
}

.dateSection {
  margin-right: 5px;
}
