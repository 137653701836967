@import '../../marketplace.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
    color: var(--matterColorGrayDark);
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0;
    color: var(--matterColorGrayDark);
    font-weight: var(--fontWeightBold);
    font-size: 20px;

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;    
    }
  }

  & h3 {
    color: var(--matterColorGrayDark);
    font-size: 18px;
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}


.disc {
  list-style: disc inside none;
  display: list-item; 
}

.wrapNoMargin p {
  margin: 0;
}

.link {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorGrayDark);
  padding: 0 6px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.linkUnderline {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorGrayDark);
  text-decoration: underline;
  padding: 0 6px;
  
  &:hover {
    color: var(--marketplaceColor);
  }
}