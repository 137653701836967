@import '../../marketplace.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
    color: var(--matterColorGrayDark);
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0;
    color: var(--matterColorGrayDark);
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;
    font-weight: var(--fontWeightSemiBold);
    font-size: 17px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}

.wrapNoMargin p {
  margin: 0;
}