@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
 
  @media (--viewportLarge) {
    padding-right: 0 !important;
    padding-left: 39px !important;
  }
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;


  @media (--viewportLarge) {
    margin-bottom: 32px;
    padding: 1px 0 7px 0;
    width: 600px;
  }
}

.subTitle{
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;

  @media(--viewportMedium) {
    line-height: 38px;
    font-size: 23px;
  }

  @media(--viewportLarge){
    max-width: 650px;
  }

  @media (--viewportLargeWithPaddings) {
    max-width: 900px;
  }
}