@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.root {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    width: 100%;
  }

  @media (--viewportLarge) {
    width: 943px;
    margin: 0 auto;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);

  @apply --SectionHero_animation;
  animation-delay: 0.5s;

  @media (min-width: 1681px) {
    font-size: 72px;
    line-height: 96px;
    letter-spacing: -2px;
  }
}

.subHeroTitle{
  animation-delay: 0.5s;
  color: var(--matterColorLight);
  font-size: 24px;
  position: relative;

  @media (--viewportMedium) {
    top: -25px;
  }
}

.wrapHeroTitle{
  display: flex;
}

.heroSubTitle {
  @apply --marketplaceBodyFontStyles;

  color: var(--matterColorLight);
  margin: 0 0 20px 0;

  @apply --SectionHero_animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    margin: 0 0 63px 0;
    font-size: 36px;
    margin-bottom: 30px;
    margin-top: -20px;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  @apply --SectionHero_animation;
  box-shadow: var(--boxShadowNotFoundPageSearch);
  border-radius: 6px;
  animation-delay: 0.8s;
  font-size: 20px;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
