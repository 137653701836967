@import '../../marketplace.css';

.logoMobile {
  height: 30px;
}

.beta {
  background-color: var(--matterColor);
  color: var(--matterColorLight);
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 10px;
}

.wrapLogo{
  display: flex;
  align-items: center;
  margin-right: -10px;
}