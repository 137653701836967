@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.policy {
  margin: 24px 0;

  @media (--viewportLargeWithPaddings) {
    margin: 50px 50px 50px 0;
  }
}

.policy label {
  @media (--viewportLargeWithPaddings) {
    font-size: 20px;
  }
}

.policy textarea{
  border-bottom-color: var(--marketplaceColor);
}

.policySuccess textarea{
  border-bottom-color: var(--successColor);
}

.error {
  color: var(--failColor);
  overflow: hidden;
  margin-top: 6px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.wrapFieldItem {
  @media(--viewportMedium){
    display: flex;
    align-items: flex-start;
  }
}

.wrapFieldItem {
  & > *:not(:last-child) {
    margin-right: 20px;
  }
}

.removeItem {
  height: 34px;
  width: 70px;
  background: var(--matterColorGray);
  border-radius: 4px;
  margin-bottom: 30px;
  margin-top: 20px;
  cursor: pointer; 
  outline: none;

  @media(--viewportMedium) {
    border-color: transparent;
    background-image: url(../../assets/icons/close.png);
    background-repeat: no-repeat;  
  }

  @media(--viewportLarge){
    width: 70px;
  }

  @media(--viewportLargeWithPaddings){
    width: 72px;
  }
}

.deleteMobile {
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);

  @media(--viewportMedium) {
    display: none; 
  }
}

.removeItemVisible {
  visibility: hidden;
  margin: 0 auto;

  @media(--viewportMedium) {
    margin: auto;
  }
}

.nameCatering,
.descriptionCatering,
.costCatering {
  margin-bottom: 10px;
  @media(--viewportMedium) {
    width: 33%;
    margin-bottom: 0;
  }

  @media(--viewportLarge) {
    width: 1000%;
  }

  @media(--viewportLargeWithPaddings) {
    width: 33%;
  }
}

.nameCatering label,
.descriptionCatering label,
.costCatering label {
  @media(--viewportLargeWithPaddings) {
    font-size: 20px;
  }
}

.addingButton {
  width: 140px;
  border-radius: 4px;
  min-height: 40px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.wrapDropdown {
  @media(--viewportLargeWithPaddings) {
    display: flex;
  }
}

.cateringType {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr;

  @media(--viewportMedium) {
    grid-template-columns: 1fr 3fr;
    margin-bottom: 24px;
  }  
}

.cateringType div:nth-child(3) {
  @media(--viewportMedium) {
    grid-column: 2 / 3;
  }  
}

.contentCatering {
  width: 320px;

  @media(--viewportMedium) {
    width: 350px;
  }
}

.contentMinBooking {
  width: 295px;
}

.labelCateringDropdown {
  margin-bottom: 8px;
  font-size: 16px;

  @media(--viewportMedium) {
    margin-bottom: 0;
  }

  @media(--viewportLargeWithPaddings) {
    font-size: 20px;
  }
}

.labelMinBooking {
  min-width: fit-content;
  font-size: 16px;
  margin-bottom: 8px;

  @media(--viewportMedium) {
    margin-bottom: 0;
  }

  @media(--viewportLargeWithPaddings) {
    font-size: 20px;
  }
}

.minBooking {
  display: grid;
  @media(--viewportMedium) {
    grid-template-columns: 1fr 3fr;
  }
}

.minBooking div:nth-child(3) {
  @media(--viewportMedium) {
    grid-column: 2 / 3;
  }
}