@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
  overflow: hidden;
  margin-top: 6px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.wrapFieldItem {
  @media(--viewportLargeWithPaddings) {
      display: flex;
      align-items: flex-start;
  }
}

.wrapFieldItem {
  & > *:not(:last-child) {
    margin-right: 20px;
  }
}

.removeItem {
  height: 34px;
  width: 70px;
  background: var(--matterColorGray);
  border-radius: 4px;
  margin-bottom: 30px;
  margin-top: 20px;
  cursor: pointer; 
  outline: none;

  @media(--viewportLargeWithPaddings){
    width: 72px;
    border-color: transparent;
    background-image: url(../../assets/icons/close.png);
    background-repeat: no-repeat;  
  }
}

.deleteMobile {
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);

  @media(--viewportLargeWithPaddings) {
    display: none; 
  }
}

.removeItemVisible {
  visibility: hidden;
  margin: 0 auto;

  @media(--viewportMedium) {
    margin: auto;
  }
}

.rentalItem {
  margin-bottom: 10px;
  
  @media(--viewportMedium) {
    margin-bottom: 0;
  }
}

.rentalItem > label {
  margin-bottom: 5px;

  @media(--viewportMedium) {
    margin-bottom: 0;
  }
}

.fieldRename {
  max-width: 464px;
  margin-bottom: 10px;

  @media(--viewportLargeWithPaddings) {
    width: 15%;
    margin-bottom: 0;
  }
}

.descriptionRental {
  max-width: 464px;
  margin-bottom: 10px;

  @media(--viewportLargeWithPaddings) {
    width: 33%;
    margin-bottom: 0;
  }
}

.descriptionRentalForRename {
  max-width: 464px;
  margin-bottom: 10px;

  @media(--viewportLargeWithPaddings) {
    width: 16%;
    margin-bottom: 0;
  }
}

.costPerRentalItem,
.maxQuantityRental {
  margin-bottom: 10px;
  max-width: 464px;

  @media(--viewportLargeWithPaddings) {
    width: calc(11% + 5px);
  }
}

.addingButton {
  width: 140px;
  border-radius: 4px;
  min-height: 40px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media(--viewportLargeWithPaddings) {
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.contentRental {
  width: 210px;
}